import React from "react"
import { Link } from 'gatsby';
import Heading from '../../components/heading/heading';

import { withTranslation, Trans } from 'react-i18next';

import moment from 'moment';

/**
 * Renders the "knowledge" overview at /lang/knowledge
 */
class KnowledgeContent extends React.Component {

  render() {
    const { edges, t } = this.props;
    let path = '/' + this.props.lang + '/knowledge/';

    let pages = edges.filter(edge => {
      return edge.node.frontmatter.path.startsWith(path) && edge.node.frontmatter.rank != null;
    })

    let latest_blog_post = undefined;
    let blogpath = '/' + this.props.lang + '/blog/';
    for (let i = 0; i < edges.length; i++) {
      let n = edges[i].node;
      if (n.frontmatter.path.startsWith(blogpath)) {
        if (latest_blog_post === undefined) {
          latest_blog_post = n;
        } else {
          if (moment(n.frontmatter.date).isAfter(moment(latest_blog_post.frontmatter.date))) {
            latest_blog_post = n;
          }
        }
      }
    }

    // If the title doesn't end with a punctuation mark, we insert a ':'. Otherwise we leave
    // everything as it is.
    let latest_blog_post_title = latest_blog_post.frontmatter.title;
    if (!(latest_blog_post_title.endsWith('!') || latest_blog_post_title.endsWith('.') || latest_blog_post_title.endsWith(':') || latest_blog_post_title.endsWith(';') || latest_blog_post_title.endsWith('?'))) {
      latest_blog_post_title = latest_blog_post_title + ':';
    }

    let nodes = pages.map(page => {
      return (
        <div key={page.node.frontmatter.path}>
          <Heading as="h4" className="article-heading"><Link to={page.node.frontmatter.path}>{page.node.frontmatter.title}</Link></Heading>
          <p>{page.node.frontmatter.abstract}</p>
          <div className="continue-link"><Link to={page.node.frontmatter.path}>{t('Knowledge.read_more')}</Link></div>
        </div>
      )
    })

    return (
      <>
        <Heading as="h1" className="page">{t('Knowledge._headline')}</Heading>
        <Heading as="h2" className="page">{t('Knowledge.blog_headline')}</Heading>

        <Trans i18nKey='Knowledge.blog_intro'><p>Read all you ever wanted to know about food, nutrition and sports right here in one spot.</p></Trans>
        <Trans i18nKey='Knowledge.blog_all_articles'><p><Link to={blogpath + 'articles'}>View all Articles</Link></p></Trans>


        <p>{t('Knowledge.blog_latest_entry')}</p>
        <p><Link to={latest_blog_post.frontmatter.path}>{latest_blog_post_title} {latest_blog_post.frontmatter.abstract}</Link></p>
        

        <hr className="m-5" />

        <Heading as="h2" className="page">{t('Knowledge.help_headline')}</Heading>
        <Trans i18nKey='Knowledge._intro'><p>Welcome to Nutrfy! With Nutrfy, you can plan, track and sync your nutrition to your training.
          Below you'll find some information to get you started.</p>
          <p>If you can't find what you're looking for, please <a href="mailto:support@nutrfy.com">contact us and we'll try our best to help you</a>.
        </p>
        </Trans>
        {nodes}
      </>
    )
  }
}

export default withTranslation()(KnowledgeContent);



